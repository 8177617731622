import Vue from "vue";

export async function show() {
  const response = await Vue.prototype.$http.get("configs/emails/unities");

  return response.data;
}

export async function update(payload) {
  const response = await Vue.prototype.$http.post(
    "configs/emails/unities",
    payload
  );

  return response.data;
}
