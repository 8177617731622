<template>
  <ConfigPage>
    <ConfigItem
      :title="'Unidades'"
      :description="'Define o email que a unidade deverá enviar as respostas de serviço'"
    >
      <v-row>
        <v-col>
          <v-form
            @submit.prevent="handleSubmit"
            :disabled="fetching || submitting"
          >
            <v-card flat class="mx-4" :disabled="fetching">
              <v-card-text>
                <v-text-field
                  v-model="form.reply_to"
                  label="Reply to"
                  type="email"
                  outlined
                  dense
                  :loading="fetching"
                  hint="Redireciona o remente para responder a este email. Aparece também no corpo das mensagens."
                ></v-text-field>
                <v-text-field
                  v-model="form.cc"
                  label="Cc"
                  type="email"
                  outlined
                  dense
                  :loading="fetching"
                ></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn type="submit" color="primary" :loading="submitting">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </ConfigItem>
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";

import { show, update } from "@/services/configs/emails_unities_reply_to.js";

export default {
  components: { ConfigPage, ConfigItem },
  data() {
    return {
      form: {},
      fetching: false,
      submitting: false,
    };
  },

  methods: {
    async fetchData() {
      this.fetching = true;
      try {
        const { data } = await show();

        this.form = data;
      } finally {
        this.fetching = false;
      }
    },

    async handleSubmit() {
      this.submitting = true;

      try {
        const { data } = await update(this.form);

        this.form = data;
        this.$store.commit("sendMessage", {
          text: "Salvo!",
        });
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível mudar o email de resposta das unidades",
          color: "red",
        });

        throw error;
      } finally {
        this.submitting = false;
      }
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
